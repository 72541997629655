import React from 'react'
import def from './default'

const cs: (typeof def) = {
    appname: 'Courses Signpost',
    apptitle: 'FIT ČVUT Předměty',
    build: {
        tooltip: 'Otevřít poslední build stránek na GitLabu',
        failed: 'Posl. build selhal',
        success: 'Posl. build úspěšný',
    },
    courses: {
        gradeTypes: {
            assesPoints: 'Zápočet',
            grade: 'Známka',
            semesterPoints: 'Body ze semestru',
            url: 'URL',
        },
        showAll: 'Zobrazit všechny předměty',
        tags: {
            bachelor: 'Bakalářský',
            both: 'Obojí',
            doctoral: 'Doktorský',
            master: 'Magisterský',
            summer: 'Letní',
            winter: 'Zimní',
            // departments
            18000: 'FIT',
            18101: 'KTI',
            18102: 'KSI',
            18103: 'KČN',
            18104: 'KPS',
            18105: 'KAM',
            18106: 'KIB',
        },
    },
    dashboard: {
        studyingTitle: 'Předměty které studuji',
        teachingTitle: 'Předměty které učím',
    },
    error: {
        reqAuthErr: 'Zdá se, že vaše přihlášení vypršelo. Přihlaste se, prosím, znovu.',
        reqDescribe: (x: string): string => `Požadavek na získání dat ze serveru ${x} selhal.`,
        reqNoResponse: 'Nebyla obdržena žádná odpověď.',
        reqOtherErr: 'Jiná neznámá chyba zabraňuje získání dat.',
        reqRequestErr: 'Požadavek se zdá být neplatný. Zkuste to znovu později.',
        reqTemporaryErr: 'Server je dočasně nedostupný. Zkuste to znovu později.',
    },
    eventTypes: {
        assessment: 'ZÁP',
        courseEvent: 'AKC',
        exam: 'ZKO',
        laboratory: 'LAB',
        lecture: 'PŘE',
        tutorial: 'CVI',
    },
    footer: {
        copyright: '© 2025 Fakulta informačních technologií, České vysoké učení technické v Praze',
        ddp: {
            title: 'Double Degree Program',
            href: '/NI-DBE/'
        },
        managed: 'Vytvořilo a spravuje ICT Oddělení',
        manual: 'Manuál',
        name: 'FIT ČVUT Courses Signpost',
        reportIssue: 'Nahlásit chybu',
        sfe: {
            title: 'Státnice',
            longTitle: 'Státní závěrečné zkoušky (SZZ)',
            href: '/SZZ/',
        },
        sourceCode: 'Zdrojové kódy',
    },
    issues: {
        issuesTooltip: 'problémy',
        unassignedIssues: 'nepřiřazené problémy na GitLabu',
    },
    loginAppeal: {
        loginButton: 'Přihlaste se svým ČVUT účtem',
        text: <p>
            Další funkce jsou dostupné po přihlášení.
            Upravte si stránku podle vašich představ — zobrazujte relevantní
            informace k předmětům které studujete nebo vyučujete, ze systému
            jako je Grades, Timetable, nebo KOS.
        </p>,
    },
    search: {
        placeholder: 'Vyhledávání v předmětech...',
        title: 'Výsledky vyhledávání',
    },
    semesterSelector: {
        all: 'Všechny předměty',
        current: 'Aktuální předměty',
        semester: 'Semestr',
    },
    settings: {
        close: 'Zavřít',
        description: <p>
            Zde můžete nastavit vaše preference. Nastavení je uloženo pouze do Local Storage tohoto prohlížeče.
            V jedné z budoucích aktualizací aplikace se můžete těšit na perzistentní ukládání nastavení a jejich
            přenos mezi vícero zařízeními.
        </p>,
        experimentalTitle: 'Experimentální funkce',
        generalTitle: 'Obecné',
        hide: 'Skrýt',
        items: {
            language: 'Jazyk',
            upcomingVisibility: 'Zobrazit nadcházející události',
        },
        language: { cs: 'Čeština', en: 'Angličtina' },
        save: 'Uložit',
        show: 'Zobrazit',
        title: 'Nastavení',
        upcomingTitle: 'Nadcházející události',
    },
    upcoming: {
        genericTitle: <>Nadcházející události</>,
        months: [ 'led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro' ],
        noEvents: 'Žádné nadcházející události dnes, ani zítra.',
        seeMore: 'Více na timetable.fit.cvut.cz',
        todayTitle: <>Dnes</>,
        tomorrowTitle: <><span className='tomorrow'>Zítra</span></>,
    },
    userMenu: {
        login: 'Přihlásit se',
        logout: 'Odhlásit se',
        settings: 'Nastavení',
    },
    broadcastMessage: '',
}

export default cs
